import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import RefreshXeroModal from "../components/modals/refreshXeroModal/RefreshXeroModal";
import moment from "moment/moment";
import ConfirmModal from "../../../../components/CustomModal/ConfirmModal";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  disconnectXero,
  resetData,
} from "../../../../redux/Setting/SettingSlice";
import Loading from "../../../../components/LoadingIndicator/Loading";
import xero from "../../../../assets/xero.svg";

const XeroSettingsTab = ({ xeroData, entity }) => {
  const { entityId, accountingFirmId, clientSpaceId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [refreshXeroModal, setRefreshXeroModal] = useState(false);
  const [tenantValue] = useState(xeroData || []);
  const [deleteConfirmModal, setDeletConfirmModal] = useState(false);
  const { isDisconnectSuccess, isDisconnectFetching, isXeroRefreshing } =
    useSelector((state) => state.setting);

  const currentTenantName = tenantValue
    ?.filter((val) => val.tenantId === entity.tenantId)
    .map((val) => val.tenantName);

  useEffect(() => {
    if (isDisconnectSuccess) {
      navigate(
        `/accountingFirm/${accountingFirmId}/clientSpace/${clientSpaceId}/entities`
      );
      dispatch(resetData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisconnectSuccess, dispatch]);

  return (
    <div>
      {refreshXeroModal && (
        <RefreshXeroModal
          modalOpen={refreshXeroModal}
          setModalOpen={setRefreshXeroModal}
        />
      )}
      {deleteConfirmModal && (
        <ConfirmModal
          message="Are you sure you want to disconnet xero account? By disconnecting it will delete Chart of Accounts, financial settting, xero data, accounts and journal "
          declineFunction={() => setDeletConfirmModal(false)}
          confirmFunction={() => {
            dispatch(disconnectXero({ entityId, accountingFirmId }));
            setDeletConfirmModal(false);
          }}
          setModalOpen={setDeletConfirmModal}
          modalOpen={deleteConfirmModal}
        />
      )}
      {isDisconnectFetching && <Loading wait={true} />}
      <Paper
        sx={{
          border: 0,
          borderRadius: 2,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          height: "70vh",
          padding: "20px",
          width: "40vw",
        }}
      >
        <Grid container direction="column" sx={{ marginTop: 2 }}>
          <Grid item mb={7}>
            <Typography variant="h5" fontWeight="bold">
              Xero Status
            </Typography>
          </Grid>

          <Grid container item spacing={2} px={3}>
            <Grid item xs={6}>
              <Box
                sx={{
                  p: 3,
                  borderRadius: 2,
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
                  backgroundColor: "#fff",
                }}
              >
                <Typography variant="subtitle1" fontWeight="medium">
                  Connected Tenant:
                </Typography>
                <Typography variant="body1">{currentTenantName}</Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                sx={{
                  p: 3,
                  borderRadius: 2,
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
                  backgroundColor: "#fff",
                }}
              >
                <Typography variant="subtitle1" fontWeight="medium">
                  Xero last refresh:
                </Typography>
                <Typography variant="body1">
                  {/* {`Date: ${moment(entity.xeroLastRefresh).year()} / 
                  ${moment(entity.xeroLastRefresh).month()} /
                 ${moment(entity.xeroLastRefresh).date()}`} */}
                  {moment(entity?.xeroLastRefresh).format("YYYY/MM/DD hh:mm a")}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            item
            container
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={5}
            pt={2}
          >
            <Grid item xs={3} py={4}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => setDeletConfirmModal(true)}
                startIcon={
                  <img
                    style={{ height: "25px", width: "25px" }}
                    alt="xero"
                    src={xero}
                  />
                }
              >
                Disconnect from Xero
              </Button>
            </Grid>
            <Grid item xs={3} py={4}>
              <Button
                fullWidth
                onClick={() => setRefreshXeroModal(true)}
                disabled={isXeroRefreshing}
                variant="contained"
                color="primary"
              >
                Refresh Data
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default XeroSettingsTab;
